@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.l-lowerCaption__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.c-topicspath {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 15px; }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      text-align: left; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: #212c2b; }
      .c-topicspath li > a:not(.c-btn) {
        border-bottom: 1px solid #212c2b; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url("../../image/common/bg_lowerCap.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; }
  .l-lowerCaption__title {
    color: #212c2b;
    max-width: 1138px;
    padding: 0 15px;
    width: 100%;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.97;
    letter-spacing: .06em; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 32px;
        width: 100%; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 28px; } }
